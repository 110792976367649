<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>list</md-icon>
            <h3 class="pl-2 text-uppercase">Import/Export</h3>
          </div>
          <div class="flex">
            <md-button class="bg-green text-white rounded-md text-uppercase">
              <md-icon class="text-white">add</md-icon> Import
            </md-button>
            
            <md-button class="bg-green ml-2 text-white rounded-md text-uppercase">
              <md-icon class="text-white">reply</md-icon> Export
            </md-button>

          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <!-- <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
          <SelectComponent placeholder="Select one" class="w-64 m-2" />
        </div>
        <md-divider></md-divider> -->
        <Table :items="importexport" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="name" md-label="Name" class="text-victoria">{{ item.name }}</md-table-cell>
            <md-table-cell md-sort-by="designation" md-label="DESIGNATION" class="text-victoria">{{ item.designation }}</md-table-cell>
            <md-table-cell md-sort-by="branch" md-label="BRANCH" class="text-victoria">{{ item.branch }}</md-table-cell>
            <md-table-cell md-sort-by="course" md-label="COURSE" class="text-victoria">{{ item.course }}</md-table-cell>
            <md-table-cell md-sort-by="emailAddress" md-label="EMAIL ADDRESS">{{ item.emailAddress }}</md-table-cell>
            <md-table-cell md-sort-by="contact" md-label="CONTACT">{{ item.contact }}</md-table-cell>
            <md-table-cell md-sort-by="enlistedBy" md-label="ENLISTED BY">{{ item.enlistedBy }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">edit</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">delete</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
  </div>
</template>

<script>
import {
    Card,
    Table,
    CardBody
    } from "@/components";
import { importexport } from "@/data/communication/interaction"
export default {
    components: {
        Card,
        Table,
        CardBody
    },
    data() {
      return {
        importexport: importexport
      }
    }
}
</script>